import store from '@/store';

export function isBusinessOnlyUsingAgentShowings() {
  const business = store.getters['Auth/business'];
  const isBusinessPublishListingEnabled = Boolean(business?.publish_listing_showing_type_enabled_at);
  return !isBusinessSelfShowingsEnabled(business) && !isBusinessPublishListingEnabled;
}

export function isBusinessSelfShowingsEnabled(business) {
  const isCodeBoxConnected = business?.codebox_connected;
  const isContractorLockboxEnabled = Boolean(business?.self_showing_contractor_lockbox_enabled_at);

  return isCodeBoxConnected || isContractorLockboxEnabled;
}
